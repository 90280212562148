import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import { primary } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`

  @media (min-width: ${breakpoints.md}){
    height: 830px;
  }

  @media (min-width: ${breakpoints.lg}){
    height: 820px;
  }

  @media (min-width: ${breakpoints.xl}){
    height: 970px;
  }

  .react-multiple-carousel__arrow--left {
    left: -7px;
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .react-multi-carousel-dot {
    button {
      background: ${orange.extra};
    }
  }

  .react-multi-carousel-dot--active {
    button {
      background: ${orange.extra};
    }
  }

  .react-multi-carousel-track {
    @media (min-width: ${breakpoints.md}){
      padding: 30px 0 30px;
    }

    @media (min-width: ${breakpoints.lg}){
      padding: 30px 0 50px;
    }
  }

  .title {
    font-size: 24px;
    height: 60px;
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  .description {
    font-size: font-size: 16px;
    height: 40px;

    @media (min-width: ${breakpoints.md}){
      font-size: font-size: 14px;
      height: 60px;
    }

    @media (min-width: ${breakpoints.lg}){
      font-size: font-size: 16px;
    }
  }

  .open-account-btn {
    text-transform: unset;
    min-width: 312px;
    position: relative;
    top: 15px;
    
    @media (min-width: ${breakpoints.md}){
      min-width: 457px;
      top: -10px;
    }

    @media (min-width: ${breakpoints.lg}){
      top: -20px;
    }

    @media (min-width: ${breakpoints.xl}){
      top: -10px;
    }
  }
`

export const Carousel = styled.div`

  @media (min-width: ${breakpoints.md}){
    right: 0;
    height: 250px;
  }

  .bg-gray {
    @media (min-width: ${breakpoints.md}){
      min-height: 180px;
    }

    @media (min-width: ${breakpoints.xl}){
      min-height: 189px;
    }
  }
`

export const Circle = styled.div`
  border-radius: 25px;
  background-color: ${primary[500]};
  width: 44px;
  height: 44px;
  margin: 0 auto;
`
