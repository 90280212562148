import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import AccountForMinorsJSON from '../../assets/data/AccountForMinors.json'
import { Carousel, Section } from './style'

type AccountForMinorsProps = {
  image: string;
  title: string;
  description: string;
  altText: string;
}

const AccountForMinors = () => {
  return (
    <Section className='py-5 bg-gray'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-29 lh-md-34 lh-xl-52 text-grayscale--500 fw-500'>
              Por que abrir uma conta para menores no Inter?
            </h2>
          </div>
        </div>
        <div className='row'>
          <Carousel className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 90 }}
              md={{ items: 2, partialVisibilityGutter: 80 }}
              lg={{ items: 3, partialVisibilityGutter: 40 }}
              xl={{ items: 4 }}
            >
              { AccountForMinorsJSON.map((item: AccountForMinorsProps) => (
                <div key={item.title} className='mr-3 mr-md-4'>
                  <div>
                    <ImageWebp
                      pathSrc={item.image}
                      altDescription={item.altText}
                      className='mb-4 mb-md-0'
                    />
                    <h3 className='fs-20 lh-24 text-grayscale--500 fw-500 mt-4'>{item.title}</h3>
                    <p className='fs-16 lh-20 text-grayscale--400 fw-400 mt-3'>{item.description}</p>
                  </div>
                </div>
            ))}
            </DefaultCarousel>
          </Carousel>
        </div>
      </div>
    </Section>
  )
}

export default AccountForMinors
