import { device } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'
import { sand } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${sand};

  @media ${device.desktopLG} { 
    height: 684px;
  }
  
  @media ${device.desktopXL} { 
    height: 700px;
  }

  @media ${device.desktopXXXL} { 
    height: 780px;
  }

  .bg {
    z-index: +1;
    position: relative;
    top: -1px;
    height: 230px;
    background-color: ${white};
    border-radius: 20px 20px 0 0;

    @media ${device.tablet} { 
      height: 310px;
      top: 35px;
    }

    @media ${device.desktopLG} { 
      height: 270px;
    }
  }
`

export const Blog = styled.div`
  img {
    width: 100%;
      
    @media ${device.tablet} { 
      width: revert-layer;
    }

    @media ${device.desktopXXL} { 
      width: 100% !important;
    }
  }

  .text {
    p, span {
      float: left;
    }
  }

  .read-more {
    float: right;

    @media ${device.desktopLG} { 
      position: absolute;
      bottom: 10px;
    }

    @media ${device.desktopXL} { 
      right: 20px;
    }
  }

  &.p-card {
    padding: 0;
  
    @media ${device.tablet} { 
      padding-right: 24px;
    }
    @media ${device.desktopLG} { 
      padding-right: 10.5px;
      padding-left: 10.5px
    }     
  }

  article {
    display: grid;
    min-height: 350px;

    @media ${device.desktopLG} { 
      min-height: 470px;
    }
  }

  .react-multi-carousel-track {
    padding: 30px 0 10px!important;
  }

  .title-post {
    font-family: "Sora", Helvetica, sans-serif;
    @media ${device.tablet} { 
      letter-spacing: 0;
    }
    @media ${device.desktopLG} { 
      letter-spacing: 1;
    }
  }
`

export const Tag = styled.div`
  float: left;
  font-size: 12px;

  @media ${device.tablet} { 
    font-size: 11px;
  }
  
  @media ${device.desktopLG} { 
    font-size: 12px;
  }
`