import Img from 'gatsby-image'
import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import pageQuery from '../../pageQuery'

import KidsAccountData from './../../assets/data/KidsAccount.json'
import { Icon, Section, Title } from './style'

type kidsAccountProps = {
  icon: string;
  title: string;
  description: string;
  color: string;
}

const KidsAccount = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = pageQuery()

  return (
    <Section id='kids-account' className='py-5 d-flex align-items-md-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 offset-xl-1 d-flex justify-content-center justify-content-md-end order-md-last'>
            <ImageWebp
              arrayNumbers={[ 294, 276, 404, 533 ]}
              arrayNumbersHeight={[ 147, 138, 202, 266 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-1440-conta-menor/image.webp'
              altDescription='Mulher, criança e homem sorrindo em frente a um computador'
              className='mb-4 mb-md-0'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 pr-md-0'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-29 lh-md-34 lh-xl-52 text-grayscale--500 fw-500'>
              Ensine os pequenos a cuidar do dinheiro com a Conta Kids
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-md-22 fw-400 text-grayscale--500 mt-3'>
              Uma conta completa e gratuita. Pra você começar a planejar e a invetir no futuro das crianças, sem preocupação.
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 90 }}
              md={{ items: 3 }}
              lg={{ items: 4 }}
              xl={{ items: 4 }}
            >
              { KidsAccountData.map((item: kidsAccountProps) => (
                <div className='mr-4' key={item.title}>
                  <div className='d-flex'>
                    <Icon><Img fluid={data[item.icon].fluid} /></Icon>
                    <Title className='title px-2 px-md-2 py-1' style={{ background: item.color }}>
                      <p className='fs-14 fs-md-15 text-grayscale--500 fw-500 mt-1 mb-0 text-center'>{item.title}</p>
                    </Title>
                  </div>
                  <div className='content px-3 px-md-4 py-4 d-flex align-items-center'>
                    <p className='fs-14 fs-md-16 lh-20 text-grayscale--500 text-center mb-0'>{item.description}</p>
                  </div>
                </div>
            ))}
            </DefaultCarousel>
          </div>
          <div className='w-100 d-flex justify-content-center'>
            <Link
              title='Conheça a Conta Kids'
              to='/pra-voce/conta-digital/conta-kids/'
              className='btn btn-orange--extra btn--lg fs-14 fw-600 rounded-2 mx-auto text-white text-none mt-2 mt-md-3 mt-lg-0'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_02',
                element_action: 'click button',
                element_name: 'Conheça a Conta Kids',
                section_name: 'Ensine os pequenos a cuidar do dinheiro com a Conta Kids',
              })}
            >
              Conheça a Conta Kids
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default KidsAccount
